
import { useFirestoreGeneralFour } from "../../firebase/useFirestore";

const SyncCount = ({portfolioId, outputId, effectId}) => {

    const queryField = outputId ? outputId : effectId
    const queryType = outputId ? 'output' : 'effect'

    // Firestore
    const syncs = useFirestoreGeneralFour(
        'synchronisations', 'portfolioId', 
        portfolioId ? portfolioId : 'none', 
        'syncItem', queryField ? queryField : 'none',    
        'status', 'accepted',
        'type', queryType)

    

  return (
    <p id='effects-table-indicator-counter'>{syncs.length}</p>
  )
}

export default SyncCount