import { useFirestoreGeneral } from '../../firebase/useFirestore'
import IndicatorData from '../indicators/IndicatorData'

const DashboardEffectIndicators = ({effectId}) => {

    const indicators = useFirestoreGeneral('indicators', 'effectId', effectId)

  return (
    <div>
        {indicators && indicators.map(indicator => (
            <div key={indicator.id}>
              <h2>{indicator.question}</h2>
              {/* <p>{indicator.type}</p> */}
              <IndicatorData indicator={indicator} effectId={effectId} type={indicator.type} />
            </div>
        ))}
    </div>
  )
}

export default DashboardEffectIndicators