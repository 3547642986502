import { useFirestoreGeneralTwo, useFirestoreGeneralTwoOrderBy } from '../../firebase/useFirestore'
import { doc, setDoc, updateDoc, serverTimestamp, deleteDoc } from "firebase/firestore"; 
import { db } from "../../firebase/config"
import { v4 as uuid } from 'uuid';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Location from '../../helpers/Location'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Tooltip from "../../components/common/Tooltip";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const AddIndicator = () => {

   // State
   const [questionType, setQuestionType] = useState('')
   const [reachStart, setReachStart] = useState('')
   const [reachStartLabel, setReachStartLabel] = useState('')
   const [reachEnd, setReachEnd] = useState('')
   const [reachEndLabel, setReachEndLabel] = useState('')
   const [effectTitle, setEffectTitle] = useState('')
    const [indicatorDocid, setIndicatorDocid] = useState('')
    const [multipleOption, setMultipleOption] = useState('')
   
   // Hooks
   const client = Location()[3]
   const effectId = Location()[4]
   const indicatorId = Location()[5]
   const navigate = useNavigate()

   // Firestore
   const multipleQuestionOptions = useFirestoreGeneralTwo('multipleQuestionOptions', 'companyId', client ? client : '', 'indicatorId', indicatorId ? indicatorId : '')
   const effects  = useFirestoreGeneralTwo('effects', 'companyId', client ? client : '', 'id', effectId ? effectId : '')
   const matrixRows = useFirestoreGeneralTwoOrderBy('matrixRows', 'companyId', client ? client : '', 'indicatorId', indicatorId ? indicatorId : '', 'position', 'asc')
    const matrixColumns = useFirestoreGeneralTwoOrderBy('matrixColumns', 'companyId', client ? client : '', 'indicatorId', indicatorId ? indicatorId : '', 'position', 'asc')
    const indicators  = useFirestoreGeneralTwo('indicators', 'companyId', client ? client : '', 'id', indicatorId ? indicatorId : '')

    // Set the defualt values
    useEffect(() => {
        if(effects.length > 0){
            setEffectTitle(effects[0].title)
        }
    }, [effects])

    // Handle title change
    const titleHandler = async (e) => {
        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "indicators", docid), {
            title: e.target.value,
        })
    }

    // Handle question type change
    const questionTypeHandler = async (e) => {
        const docid = e.target.dataset.docid

        await updateDoc(doc(db, "indicators", docid), {
            questionType: e.target.options[e.target.selectedIndex].value,
        })

    }

    // Handle reach start change
    const reachStartHandler = async (e) => {
        const docid = e.target.dataset.docid

        updateDoc(doc(db, "indicators", docid), {
            reachStart: e.target.options[e.target.selectedIndex].value,
        })

    }

    // Handle reach start label change
    const reachStartLabelHandler = async (e) => {
        const docid = e.target.dataset.docid

        updateDoc(doc(db, "indicators", docid), {
            reachStartLabel: e.target.value,
        })

    }

    // Handle reach end change
    const reachEndHandler = async (e) => {
        const docid = e.target.dataset.docid

        updateDoc(doc(db, "indicators", docid), {
            reachEnd: e.target.options[e.target.selectedIndex].value,
        })

    }

    // Handle reach end label change
    const reachEndLabelHandler = async (e) => {
        const docid = e.target.dataset.docid

        updateDoc(doc(db, "indicators", docid), {
            reachEndLabel: e.target.value,
        })

    }

    // Handle multiple options
    const multipleHandler = async () => {

        await setDoc(doc(db, "multipleQuestionOptions", uuid()), {
            companyId: client,
            option: multipleOption,
            createdAt: serverTimestamp(),
            id: uuid(),
            effectId: effectId,
            indicatorId: indicatorId
        })

        setMultipleOption('')
    }
    
    // Delete multiple options
    const deleteOptionHandler = async (e) => {
        const docid = e.target.dataset.docid
    
        await deleteDoc(doc(db, "multipleQuestionOptions", docid))
    }

    // Add Matrix Row
    const addMatrixRow = async (e) => {

        // ButtonClicked(e, 'Toegevoegd')
        await setDoc(doc(db, "matrixRows", uuid()), {
             title: '',
             indicatorId: indicatorId,
             companyId: client,
             createdAt: serverTimestamp(),
             position: matrixRows.length + 1,
             id: uuid(),
            //  color: getRandomColor(),
         })
         
     }
 
     // Add Matrix Column
     const addMatrixColumn = async (e) => {
         
        //  ButtonClicked(e, 'Toegevoegd')
        await setDoc(doc(db, "matrixColumns", uuid()), {
             title: '',
             indicatorId: indicatorId,
             companyId: client,
             createdAt: serverTimestamp(),
             position: matrixColumns.length + 1,
             id: uuid(),
            //  color: getRandomColor(),
         })

     }
 
     // Delete Matrix Row
     const deleteMatrixRow = async (e) => {
         const docid = e.target.dataset.docid
 
         await deleteDoc(doc(db, "matrixRows", docid))
        
     }
 
     // Delete Matrix Column
     const deleteMatrixColumn = async (e) => {
         const docid = e.target.dataset.docid
 
         await deleteDoc(doc(db, "matrixColumns", docid))

     }
 
        // Edit Matrix Row
     const rowTitleHandler = async (e) => {
         const value = e.target.value
         const docid = e.target.dataset.docid

         await updateDoc(doc(db, "matrixRows", docid), {
            title: value
        })
 
     }
 
     // Edit Matrix Column
     const columnTitleHandler = async (e) => {
         const value = e.target.value
         const docid = e.target.dataset.docid

         await updateDoc(doc(db, "matrixColumns", docid), {
            title: value
        })
     }

  return (
    <div className='page-container'>
         <div className='page-top-container'>
           <div className='page-header-title-container'>
             <h1>Indicator toevoegen</h1>
             <h2>{effectTitle}</h2>
           </div>
         </div>
        <div className='add-new-indicator-container' >
            {indicators && indicators.map(item => (
               <div key={item.id}>
                    <h3>Vraag</h3>
                    <input type="text" placeholder="Noteer hier je vraag" defaultValue={item.title} data-docid={item.docid} onChange={titleHandler} />
                    <h3>Type vraag</h3>
                    <select name="" id="" value={item.questionType} data-docid={item.docid} onChange={questionTypeHandler}>
                        <option value="">-- Selecteer een type vraag --</option>
                        <option value="open">Open vraag</option>
                        <option value="scale">Schaalvraag</option>
                        <option value="multiple-one">Meerkeuze vraag (één optie)</option>
                        <option value="multiple-multiple">Meerkeuze vraag (meerdere opties)</option>
                        <option value="matrix-one">Matrix vraag (één optie)</option>
                        <option value="matrix-multiple">Matrix vraag (meerdere opties)</option>
                    </select>

                    {/* Scale */}
                    {item.questionType === 'scale' && 
                        <div>
                            <h3>Schaalvraag details</h3>
                            <div>
                                <p>Selecteer onderwaarde</p>
                                <select name="" id="" value={reachStart} data-docid={item.docid} onChange={reachStartHandler}>
                                <option value="">-- Selecteer een onderwaarde --</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                </select>
                                <input type="text" value={reachStartLabel} data-docid={item.docid} placeholder='Voeg label toe (optioneel)' onChange={reachStartLabelHandler}/>
                            </div>
                            <div>
                                <p>t/m</p>
                            </div>
                            <div>
                                <p>Selecteer bovenwaarde</p>
                                <select name="" id="" value={reachEnd} data-docid={item.docid} onChange={reachEndHandler}>
                                <option value="">-- Selecteer een bovenwaarde --</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                </select>
                                <input type="text" value={reachEndLabel} data-docid={item.docid} placeholder='Voeg label toe (optioneel)' onChange={reachEndLabelHandler}/>
                            </div>
                        </div>
                    }

                    {/* Multiple */}
                    {(item.questionType === 'multiple-one' || item.questionType === 'multiple-multiple') &&
                        <div>
                            <h3>Meerkeuze vraag details</h3>
                            <div>
                                <p>Voeg optie toe</p>
                                {/* <p>Of voeg 'Overige' toe</p> */}
                                <input type="text" placeholder="Noteer hier je optie" onChange={(e) => setMultipleOption(e.target.value)} />
                                <button onClick={multipleHandler}>Voeg toe</button>
                            </div>
                            <div>
                                <ul>
                                {multipleQuestionOptions && multipleQuestionOptions.map(option => (
                                    <div key={option.id}>
                                    <li >{option.option}</li>
                                    <DeleteOutlineOutlinedIcon data-docid={option.docid} onClick={deleteOptionHandler} />
                                    </div>
                                ))}
                                </ul>
                            </div>
                        </div>
                    }

                    {/* Matrix */}
                    {(item.questionType === 'matrix-one' || item.questionType === 'matrix-multiple') &&
                        <div>
                            <h3>Matrix vraag details</h3>
                            <div className='add-matrix-container'>
                                <div className='add-matrix-item-container'>
                                <Tooltip content='Rij toevoegen' top='-60px' width={'25px'}>
                                    <AddCircleOutlineOutlinedIcon className='add-matrix-item-icon' onClick={addMatrixRow} />
                                </Tooltip>
                                <p>Voeg een rij toe</p>
                                </div>
                                <div className='add-matrix-item-container'>
                                <Tooltip content='Kolom toevoegen' top='-60px' width={'25px'}>
                                    <AddCircleOutlineOutlinedIcon className='add-matrix-item-icon' onClick={addMatrixColumn} />
                                </Tooltip>
                                <p>Voeg een kolom toe</p>
                                </div>
                            </div>

                            <div id='matrix-grid-container' 
                                style={{
                                display: 'grid',
                                gridTemplateColumns: `150px repeat(${matrixColumns.length}, 1fr)`, 
                                gap: '10px'
                                }}>
                            {/* Matrix column titles */}
                            <div className='matrix-grid'>
                                {/* Placeholder for empty top-left cell */}
                                <div className='matrix-grid-header'></div>
                                {matrixColumns && matrixColumns.map((column) => (
                                <div key={column.id} className='matrix-grid-header'>
                                    <input type="text" defaultValue={column.title} placeholder='Naam van kolom' data-docid={column.docid} onChange={columnTitleHandler} />
                                    <Tooltip content={`Verwijder ${column.title}`} top='-60px'>
                                    <DeleteOutlineOutlinedIcon className='matrix-delete-icon' data-docid={column.docid} onClick={deleteMatrixColumn} alt="" />
                                    </Tooltip>
                                </div>
                                ))}
                            </div>

                            {/* Matrix rows and corresponding checkboxes */}
                            {matrixRows && matrixRows.map((row) => (
                                <div key={row.id} className='matrix-grid-row'>
                                <div className='matrix-grid-header'>
                                    <input type="text" defaultValue={row.title} placeholder='Naam van rij' data-docid={row.docid} onChange={rowTitleHandler} />
                                    <Tooltip content={`Verwijder ${row.title}`} top='-60px'>
                                    <DeleteOutlineOutlinedIcon alt="" className='matrix-delete-icon' data-docid={row.docid} onClick={deleteMatrixRow} />
                                    </Tooltip>
                                </div>
                                {matrixColumns && matrixColumns.map((column) => (
                                    <div key={column.di} className='matrix-grid-item'>
                                    <input id='matrix-column-input' type="checkbox" value={column.Title} />
                                    </div>
                                ))}
                                </div>
                            ))}
                            </div>
                        </div>
                    }
                </div>
            ))}
        </div>
    </div>
  )
}

export default AddIndicator